<template>
  <v-app class="area-theme">
    <v-main class="area-container">
      <slot />
    </v-main>

    <v-footer app fixed color="transparent" height="98">
      <CommonFloatingNavigation />
    </v-footer>
    <OnboardingDialog />
  </v-app>
</template>

<style lang="sass" scoped>
.area-theme
  font-family: $body-font-family
  background-color: $white-bg-color !important
  color: $main-color !important

.area-container
  padding: 0 0 98px 0 !important
</style>
